<template>
  <div class="filterView">
    <div class="cell-contianer" v-for="(item, index) in items" :key="index">
      <div v-if="index < limitCount" class="flex-row cell">
        <div class="title">{{ item.title }}:</div>
        <div class="flex-row child-container">
          <div
            :class="['child', selectsObj[item.key] === child.id ? 'child-on' : '']"
            v-for="(child, j) in item.children"
            :key="j"
            @click="selectCell(item, child)"
          >
            {{ child.name }}
          </div>
        </div>
      </div>
    </div>
    <div class="fold-btn" @click="foldBtnClick">
      <span>{{ isOpening ? "收起" : "全部展开" }}</span>
      <Icon
        :type="isOpening ? 'ios-arrow-up' : 'ios-arrow-down'"
        size="16"
        color="rgba(45, 129, 255, 1);"
      />
    </div>
  </div>
</template>

<script>
//  项目申报 组件
export default {
  name: "filterView",
  data() {
    return {
      limitCount: 3,
      isOpening: false,
      selectsObj: {}, // 选择的key值，用于传回父类
      items: [
        {
          id: 0,
          title: "申报状态",
          key: "sbStatus",
          children: [
            { id: 0, name: "不限" },
            { id: 1, name: "申报中" },
            { id: 2, name: "已截止" },
            { id: 3, name: "未开始" },
          ],
        },
        {
          id: 1,
          title: "服务对象",
          key: "servObj",
          children: [
            { id: 0, name: "不限" },
            { id: 1, name: "法人" },
            { id: 2, name: "自然人" },
          ],
        },
        {
          id: 2,
          title: "选择区划",
          key: "regionCode",
          children: [
            { id: 0, name: "不限" },
            // { id: 1, name: "新安街道" },
            // { id: 2, name: "西乡街道" },
            // { id: 3, name: "航城街道" },
            // { id: 4, name: "福永街道" },
            // { id: 5, name: "福海街道" },
            // { id: 6, name: "沙井街道" },
            // { id: 7, name: "新桥街道" },
            // { id: 8, name: "松岗街道" },
            // { id: 9, name: "燕罗街道" },
            // { id: 10, name: "石岩街道" },
          ],
        },
        {
          id: 3,
          title: "奖金范围",
          key: "capital",
          children: [
            { id: 0, name: "不限" },
            { id: 1, name: "100万元以上" },
            { id: 2, name: "50~100万" },
            { id: 3, name: "10~50万" },
            { id: 4, name: "0~10万" },
          ],
        },
        {
          id: 4,
          title: "主管部门",
          key: "deptCode",
          children: [
            { id: 0, name: "不限" },
            // { id: 1, name: "文化广电旅游体育局" },
            // { id: 2, name: "人力资源和社会保障局" },
            // { id: 3, name: "市场监督管理局" },
            // { id: 4, name: "发展和改革局" },
            // { id: 5, name: "经济和科技促进局" },
          ],
        },
        {
          id: 5,
          title: "支持领域",
          key: "typeCode",
          children: [
            // { id: 0, name: "不限" },
            // { id: 1, name: "人才引育类" },
            // { id: 2, name: "科技创新类" },
            // { id: 3, name: "产业投资类" },
            // { id: 4, name: "载体建设类" },
            // { id: 5, name: "市场开拓类" },
            // { id: 6, name: "对上配套类" },
            // { id: 6, name: "品牌建设类" },
            // { id: 6, name: "业绩奖励类" },
          ],
        },
        {
          id: 6,
          title: "项目年度",
          key: "year",
          children: [
            { id: 0, name: "不限" },
            { id: 1, name: "2021年" },
            { id: 2, name: "2020年" },
            { id: 3, name: "2019年" },
            { id: 4, name: "2018年" },
            { id: 5, name: "2017年" },
          ],
        },
        {
          id: 7,
          title: "申报月份",
          key: "month",
          children: [
            { id: 0, name: "不限" },
            { id: 1, name: "1月" },
            { id: 2, name: "2月" },
            { id: 3, name: "3月" },
            { id: 4, name: "4月" },
            { id: 5, name: "5月" },
            { id: 6, name: "6月" },
            { id: 7, name: "7月" },
            { id: 8, name: "8月" },
            { id: 9, name: "9月" },
            { id: 10, name: "10月" },
            { id: 11, name: "11月" },
            { id: 12, name: "12月" },
          ],
        },
      ],
    };
  },
  created() {
    this.loadRegion();
    this.loadDeptList();
    this.loadBasicInfoList();
  },
  methods: {
    // 主管部门
    loadDeptList() {
      this.$httpApi.get("/dev-api/system/dept/list", {}).then((res) => {
        console.log("dept list:", res);
        if (res.code == 200) {
          let result = [{ id: 0, name: "不限" }];
          let list = res.rows;
          for (const i in list) {
            if (Object.hasOwnProperty.call(list, i)) {
              const element = list[i];
              result.push({
                id: element.deptId,
                name: element.deptName,
              });
            }
          }
          this.$set(this.items, 4, {
            title: "主管部门",
            key: "deptCode",
            children: result,
          });
          this.initSelect();
        }
      })
	  .catch((err) => {
	    
	  });
    },
    // 支持领域
    loadBasicInfoList() {
      this.$httpApi.get("/dev-api/basicinfo/type/list", {}).then((res) => {
        console.log("basicinfo list:", res);
        if (res.code == 200) {
          let result = [{ id: 0, name: "不限" }];
          let list = res.rows;
          for (const i in list) {
            if (Object.hasOwnProperty.call(list, i)) {
              const element = list[i];
              result.push({
                id: element.itemTypeCode,
                name: element.typeName,
              });
            }
          }
          this.$set(this.items, 5, {
            title: "支持领域",
            key: "typeCode",
            children: result,
          });
          this.initSelect();
        }
      })
	  .catch((err) => {
	    
	  });
    },
    // 区划
    loadRegion() {
      this.$httpApi.get("/dev-api/cfginfo/region/cascaderTreeList", {}).then((res) => {
        console.log("cascaderTreeList:", res);
        if (res.code == 200) {
          let rowsItem = res.rows[0];
          // 添加的第一个是区
          let result = [
            {
              id: "", //传区就相当于不限
              name: "XX区",
              // id: rowsItem.id,
              // name: rowsItem.label,
            },
          ];
          let list = rowsItem.children;
          for (const i in list) {
            if (Object.hasOwnProperty.call(list, i)) {
              const element = list[i];
              result.push({
                id: element.id,
                name: element.label,
              });
            }
          }
          this.$set(this.items, 2, {
            title: "选择区划",
            key: "regionCode",
            children: result,
          });
          this.initSelect();
          // console.log("result:", this.items);
        }
      })
	  .catch((err) => {
	    
	  });
    },

    initSelect() {
      for (const i in this.items) {
        const item = this.items[i];
        if (item.children && item.children.length > 0) {
          // 默认都选中第0个
          this.selectsObj[item.key] = item.children[0].id;
        }
      }
    },
    selectCell(item, child) {
      this.selectsObj[item.key] = child.id;
      this.$forceUpdate();
      let data = {};
      for (const key in this.selectsObj) {
        if (Object.hasOwnProperty.call(this.selectsObj, key)) {
          const value = this.selectsObj[key];
          if (value != 0) {
            // 第一列 不限的就不传
            data[key] = value;
          }
        }
      }
      console.log("value", data);
      this.$emit("changeSelect", data);
    },
    foldBtnClick() {
      this.isOpening = !this.isOpening;
      if (this.isOpening) {
        this.limitCount = this.items.length + 1;
      } else {
        this.limitCount = 3;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.filterView {
  padding: 20px 0;
}
.cell {
  padding: 6px 0;
  color: #888888;
  font-size: 13px;
  font-family: "Microsoft YaHei";
  border-bottom: 1px dotted #bbbbbb;
  font-weight: 500;
  .title {
    margin-right: 30px;
    color: #000;
    font-size: 15px;
    flex-shrink: 0;
  }
  .child-container {
    flex-wrap: wrap;
    .child {
      margin-right: 16px;
      cursor: pointer;
      flex-shrink: 0;
      padding: 8px 0;
    }
    .child-on {
      color: rgba(45, 129, 255, 1);
    }
  }
}
.fold-btn {
  padding: 8px 0;
  //   border-radius: 8px;
  background-color: #f1f1f1;
  color: rgba(45, 129, 255, 1);
  font-size: 14px;
  text-align: center;
  margin-top: 15px;
}
</style>
