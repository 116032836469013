<template>
  <div>
    <div class="banner-size">
      <div class="banner-limit banner-info">
        <span class="banner-fMax">项目申报</span>
        <span class="banner-fMin banner-other"
          >送政策、送服务，助推企业发展</span
        >
      </div>
    </div>
    <div class="projectList width-limit" ref="projectList">
      <FilterView @changeSelect="changeSelect"></FilterView>
      <div class="items-container">
        <div class="item" v-for="(item, i) in showList" :key="i">
          <DeclareCell :item="item" @clickCell="clickCell"></DeclareCell>
        </div>
      </div>
      <div class="pagination">
        <el-pagination
          :hide-on-single-page="true"
          background
          layout="total, prev, pager, next, jumper"
          :page-size="pagerSize"
          :total="totalSize"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import DeclareCell from "@/components/declareCell"; // 申报项目，智能推荐卡片
import FilterView from "@/components/filterView";
import { Loading } from "element-ui";
export default {
  name: "projectList",
  components: {
    FilterView,
    DeclareCell,
  },
  data() {
    return {
      targetArray: [],
      showList: [],
      pagerSize: 12, // 3行 4列
      totalSize: 0,
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData(param) {
      if (param == null) {
        param = {};
      }
      this.totalSize = 0;
      this.targetArray = [];
      this.showList = [];
      param.pageNum = 0;
      param.pageSize = 100;
      let url = "/dev-api/basicinfo/baseitem/xmsbList";
      let loadingInstance = Loading.service({ target: this.$refs.projectList });
      this.$nextTick(() => {
        // 以服务的方式调用的 Loading 需要异步关闭
        this.$httpApi.get(url, param).then((res) => {
          loadingInstance.close();
          console.log("项目列表:", res);
          if (res.code == 200) {
            let result = [];
            result = res.rows;
            this.totalSize = result.length;
            this.targetArray = this.grouping(result, this.pagerSize);
            this.showList = this.targetArray[0];
          }
        })
		.catch((err) => {
		  loadingInstance.close();
		});
      });
    },
    grouping(array, n) {
      let index = 0;
      let newArray = [];
      while (index < array.length) {
        newArray.push(array.slice(index, (index += n)));
      }
      console.log("处理后的数组：", newArray);
      return newArray;
    },
    /* 分页切换时，更换显示的数组 */
    handleCurrentChange(e) {
      // console.log("页面转跳", e);
      this.showList = this.targetArray[e - 1];
    },
    changeSelect(e) {
      console.log("changeSelect:", e);
      this.loadData(e);
    },
    clickCell(item) {
      // console.log("选择的是:", item);
      this.$store.commit("updateCurrentGuideId", item.itemBaseCode);
      this.$router.push({
        name: "guide",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/com/theme.scss";
.banner-size {
  background: url("../../assets/images/icon_banner_declare.png");
}
.banner-info {
  height: 100%;
  display: flex;
  align-items: center;
  .banner-other {
    margin-left: 15px;
  }
}
.section-header {
  border-bottom: 4px solid #000;
}

.section-title {
  font-size: 24px;
  color: rgb(13, 28, 40);
  font-weight: 600;
  height: 46px;
  line-height: 46px;
}
.rank-section {
  padding-top: 10px;
  font-size: 13px;
}

.items-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  .item {
    width: calc((1200px - 60px) / 4);  // 每行4列，则有3个间距 每个间距20px
    box-sizing: border-box;
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .item:nth-child(4n + 4) {
    margin-right: 0px;
  }
}
.pagination {
  padding: 30px 0;
  margin: 0 auto;
  text-align: center;
}
</style>
